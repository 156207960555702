import { graphql } from 'gatsby';
import React, { FunctionComponent } from 'react';
import { CmsContext } from 'logic/context/cms';
import { App } from 'app/app';
import { Seo } from 'components/seo/seo';

const PressPage: FunctionComponent<any> = ({ data: { contentfulPressPage } }) => {
  return (
    <CmsContext.Provider value={contentfulPressPage}>
      <Seo
        title={contentfulPressPage.metaTags.metatitle}
        description={contentfulPressPage.metaTags.metadescription}
      />
      <App />
    </CmsContext.Provider>
  );
};

export default PressPage;

export const query = graphql`
  query PressPage {
    contentfulPressPage {
      headline
      metaTags {
        metatitle
        metadescription
      }
      pressEntries {
        ... on ContentfulPressEntry {
          image {
            fluid(quality: 100, background: "rgb:000000") {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          headline
          author
          date
          link
          category
        }
      }
    }
  }
`;
